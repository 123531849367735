import { Component, AfterViewInit, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { SeoService } from './core/seo.service';
import { UserStore } from './core/stores/user.store';
import { UserService } from './core/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(
    private ga: GoogleAnalyticsService,
    private seo: SeoService,
    private userStore: UserStore,
    private userService: UserService
  ) {
    this.seo.addSeoData();
    // Only run this when there is a google analytics present.
    this.ga.track();
  }

  ngOnInit(): void {
    if (this.userStore.isLoggedIn() === true) {
      this.refreshCredits();
    }
  }

  ngAfterViewInit() {

  }

  private async refreshCredits() {
    const credits = await this.userService.fetchUserCredits();
    this.userStore.setCredits(credits);
  }
}
