import {Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'logout-dialog',
    templateUrl: 'logout-dialog.html',
    styleUrls: ['./logout-dialog.scss']
  })
export class LogoutDialog {

    constructor(
        public dialogRef: MatDialogRef<LogoutDialog>,
        ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
