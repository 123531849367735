import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl = environment.apiEndpointUrl;

  constructor(private http: AppHttpService, private httpClient: HttpClient) {

  }

  public fetchUserCredits() {
    return this.http.get(this.baseUrl + 'api/user/credits', {headers: this.http.createAuthHeader()}).then(result => {
      return result.credits;
    });
  }

  
  public addUserCredits(credits: number, userId: number) {
    return this.httpClient.post(this.baseUrl + 'api/user/credits', {credits: credits, userId }, {headers: this.http.createAuthHeader()});
  }

  public getUser() {
    return this.http.get(this.baseUrl + 'api/user', {headers: this.http.createAuthHeader()});
  }

  public updateUserCompany(user) {
    return this.http.post(this.baseUrl + 'api/user/company', user, {headers: this.http.createAuthHeader()});
  }

  public getSubscriptions() {
    return this.httpClient.get<any[]>(this.baseUrl + 'auth/subscription');
  }

  public getSubscriptionById(id: number) {
    return this.http.get(this.baseUrl + 'auth/subscription/' + id);
  }

  public createSubscription(id: number) {
    return this.http.post(this.baseUrl + 'api/subscription/create', {subscriptionId: id}, {headers: this.http.createAuthHeader()});
  }

  public getPaymentStatus(orderId: string) {
    return this.http.get(this.baseUrl + 'api/payment/status', {headers: this.http.createAuthHeader(), params: {orderId: orderId}});
  }

  public isFirstVisit() {
    let first = false;
    const lsProperty = 'hasVisited';
    if (localStorage.getItem(lsProperty) === 'V') {
    } else {
      first = true;
      localStorage.setItem(lsProperty, 'V');
    }

    return first;

  }


}
