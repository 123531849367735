import { Component, OnInit } from '@angular/core';
import { SpinService } from '../../../core/spin.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class SpinComponent implements OnInit {

  constructor(private spinService: SpinService) { }

  ngOnInit() {
  }

  public get showMyself(): boolean {
    return this.spinService.showSpinner;
  }

}
