
<h1>Admin</h1>

<mat-form-field>
    <input [formControl]="query" matInput placeholder="Filter">
</mat-form-field>

<table mat-table [dataSource]="users$ | async" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email. </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef> CompanyName </th>
            <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
        </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="mollieId">
        <th mat-header-cell *matHeaderCellDef> MollieId </th>
        <td mat-cell *matCellDef="let element"> {{element.mollieId}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="moneybirdContactId">
        <th mat-header-cell *matHeaderCellDef> MoneybirdId </th>
        <td mat-cell *matCellDef="let element"> {{element.moneybirdContactId}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="subscriptionId">
        <th mat-header-cell *matHeaderCellDef> subscriptionId </th>
        <td mat-cell *matCellDef="let element"> {{subscriptionIdMap[element.subscriptionId]}} </td>
    </ng-container>

    <!-- Credits -->
    <ng-container matColumnDef="credits">
        <th mat-header-cell *matHeaderCellDef> Credits </th>
        <td mat-cell *matCellDef="let element"> {{element.credits}} </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Acties </th>
        <td mat-cell *matCellDef="let element">
        
            <button (click)="cancelSubscription(element.id)">Cancel subscription</button>

            <button (click)="addCredits(element.id)">Set credits</button>

        </td>
    </ng-container>

        <!-- Credits -->
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> createdAt </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'medium'}} </td>
        </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>