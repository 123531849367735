import { Component, OnInit, AfterViewInit, } from "@angular/core";
import { AuthService } from "../../core/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MapInteractionService } from "../../core/map-interaction.service";
import { DownloadInformationDialog } from "../../shared/dialogs/download-information-dialog";
import { DownloadRequestDialog } from "../../shared/dialogs/download-request-dialog";
import { ZoomInformationDialog } from "../../shared/dialogs/zoom-information-dialog";

import { Router, NavigationEnd, RouterEvent, RouterModule } from "@angular/router";
import { PleaseRegisterDialog } from "../../shared/dialogs/please-register-dialog";
import { LogoutDialog } from "../../shared/dialogs/logout-dialog";
import { UserStore } from '../../core/stores/user.store';
import { pluck, map, filter, tap } from 'rxjs/operators';
import { SpinComponent } from "./spin/spin.component";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from "@angular/common";


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    SpinComponent,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSelectModule,
    MatSnackBarModule
  ],

})
export class HeaderComponent implements OnInit, AfterViewInit {
  constructor(
    // private authService: AuthService,
    // private mapInteractionService: MapInteractionService,
    private dialog: MatDialog,
    private userStore: UserStore,
    private router: Router
  ) {
  }
 //@ts-ignore
  public user$ = this.userStore.currentUser$
  public userName$ = this.user$.pipe(
    filter(user => user !== null),
    pluck('email')
  );
  public credits$ = this.user$.pipe(
    filter(user => user !== null),
    pluck('credits')
  );
  public authenticated$ = this.user$.pipe(
    map(user => user !== null),
  );


  public kaartViewActive = true;


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setKaartViewActiveStatus(event);
      }
    });
  }

  public setKaartViewActiveStatus(event: RouterEvent) {
    // This should symbolize XYZ params. Not a permanent fix, but works until the viewer is greatly expanded.
    const urlLength = event.url.split("/").length;
    if (event.url === "/" || urlLength === 4) {
      this.kaartViewActive = true;
    } else {
      this.kaartViewActive = false;
    }
  }

  public logout() {
    // this.authService.logout();
  }

  public openDownloadDialog() {
    if (!this.userStore.isLoggedIn()) {
      this.dialog.open(PleaseRegisterDialog, {
        width: "500px",
        panelClass: "bgt-dialog",
        autoFocus: false
      });
      return;
    }

    if (this.userStore.hasEnoughCredits()) {
      if (true) {
        const dialogRef = this.dialog.open(DownloadRequestDialog, {
          width: "500px",
          panelClass: "bgt-dialog",
          autoFocus: false
        });
      } else {
        // zoommelding werkt nog niet bij een lager zoomniveau
        const dialogRef = this.dialog.open(ZoomInformationDialog, {
          width: "500px",
          panelClass: "bgt-dialog",
          autoFocus: false
        });
      }
    } else {
      const dialogRef = this.dialog.open(DownloadInformationDialog, {
        width: "500px",
        panelClass: "bgt-dialog",
        autoFocus: false
      });
    }
  }

  public openLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialog, {
      width: "350px",
      height: "165px",
      panelClass: "bgt-dialog",
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(iWantToLogout => {
      if (iWantToLogout) {
        this.userStore.clearCurrentUser();
        this.logout();
      }
    });
  }
}
