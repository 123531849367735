import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { AdminService } from '../../core/admin.service';
import { startWith, switchMap, tap } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule
  ],
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  displayedColumns: string[] = ['actions', 'email', 'companyName', 'mollieId', 'moneybirdContactId', 'subscriptionId', 'credits', 'createdAt'];
  dataSource = [];
  public query = new FormControl('');
  public q$ = this.query.valueChanges.pipe(
    startWith(''),
  );

  public subscriptionIdMap = {
    0: 'Geen',
    1: 'Brons',
    2: 'Zilver',
    3: 'Goud',
  }

  constructor(private adminService: AdminService) { }


  ngOnInit(): void {

  }

  public users$ = this.q$.pipe(
    switchMap(q => this.adminService.listUsers(q)),
  )

  public cancelSubscription(userId: number) {
    console.log(userId)
    this.adminService.cancelSubscription(userId).subscribe(() => {
      console.log('subscription cancelled')
      window.location.reload();

    })

  }

  public async addCredits(userId: number) {

    const amount = await prompt('Hoeveel credits moeten het worden', `10`)

    this.adminService.addUserCredits(+amount, userId).subscribe(() => {
      window.location.reload();
      console.log('credits added')
    })
  }



}
