<div class="page">
  <div class="header">
    <div class="container">
      <div class="header__text">
        <h1>Over de Basisregistratie Grootschalige Topografie</h1>
        <p>
          De BGT is dé basiskaart van Nederland: verschillende bronhouders,
          waaronder gemeentes, provincies en waterschappen, houden op accurate
          wijze bij wat er zich in de openbare ruimte bevindt. Daardoor
          beschikken burgers, het bedrijfsleven en overheden altijd over
          dezelfde informatie.
        </p>
      </div>
      <img src="./assets/images/bgt-preview.jpg" class="header__image slide" width="801" height="503" />
    </div>
  </div>

  <div class="info">
    <div class="container">
      <div class="info__text">
        <h2>Dé basiskaart van Nederland</h2>
        <p>
          De Basisregistratie Grootschalige Topografie is misschien wel de meest
          omvangrijke geodataset ter wereld en speelt een centrale rol in
          informatievoorziening door overheden. De BGT is een gedetailleerde
          kaart van Nederland waarin objecten zoals gebouwen, wegen, water,
          spoorlijnen en groen op eenduidige manier worden vastgelegd. De kaart
          is op 20 centimeter nauwkeurig, altijd zeer actueel en landsdekkend.
          Dat maakt de BGT bijzonder geschikt voor iedereen die zich bezighoudt
          met ruimtelijke gegevens.
        </p>
      </div>
      <div class="infoboxes">
        <div class="infobox">
          <mat-icon>calendar_today</mat-icon>
          <h3>Actueel</h3>
          <p>
            De BGT wordt door de bronhouders dagelijks bijgewerkt met recente
            informatie.
          </p>
        </div>
        <div class="infobox">
          <mat-icon>account_balance_wallet</mat-icon>
          <h3>Gratis</h3>
          <p>
            De informatie uit de BGT is kosteloos voor iedereen beschikbaar, wat
            tot forse kostenbesparingen kan leiden.
          </p>
        </div>
        <div class="infobox">
          <mat-icon>group</mat-icon>
          <h3>Samenwerking</h3>
          <p>
            De BGT maakt een betere samenwerking tussen overheden mogelijk, want
            iedereen hanteert dezelfde standaard.
          </p>
        </div>
      </div>

      <a routerLink="/" class="button button--center">
        BGTviewer gebruiken
      </a>
    </div>
  </div>
  <div class="related">
    <div class="container">
      <app-link-about-bgt-viewer></app-link-about-bgt-viewer>
      <app-link-download-bgt></app-link-download-bgt>
      <app-link-bgt-wfs></app-link-bgt-wfs>
    </div>
  </div>
  <app-call-to-action></app-call-to-action>
</div>
