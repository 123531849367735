import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../core/user.service";
import { MapInteractionService } from "../../core/map-interaction.service";
import { BgtService } from "../../core/bgt.service";
import saveAs from "file-saver";
import { UserStore } from '../../core/stores/user.store';
import { pluck } from 'rxjs/operators';
import { GoogleAnalyticsService } from "../../core/google-analytics.service";
@Component({
  selector: "download-request-dialog-component",
  templateUrl: "download-request-dialog.html",
  styleUrls: ["./download-request-dialog.scss"]
})
export class DownloadRequestDialog {
  public selectedOption = "dwg";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DownloadRequestDialog>,
    public userService: UserService,
    private userStore: UserStore,
    private mapInteractionService: MapInteractionService,
    private snackbar: MatSnackBar,
    private bgtService: BgtService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  public userCredits$ = this.userStore.currentUser$.pipe(pluck('credits'));
  public userName$ = this.userStore.currentUser$.pipe(pluck('email'));

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeInfocard() {
    this.dialogRef.close();
  }

  download() {
    this.closeInfocard();
    this.snackbar.open(
      "Uw download wordt voorbereid. Een moment geduld alstublieft."
    );
    this.googleAnalyticsService.event('download', {
      currency: "EUR",
      value: 3,
      fileType: this.selectedOption
    });
    this.bgtService
      .downloadFeatures(
        this.mapInteractionService.mapForStatistics.getBounds(),
        this.selectedOption
      )
      .then(returnedData => {
        this.userStore.useCredit();
        if (this.selectedOption === "shape-zip") {
          saveAs(returnedData, "BGT.zip");
        }
        if (this.selectedOption === "dwg") {
          saveAs(returnedData, "BGT.zip");
        }
        if (this.selectedOption === "dxf") {
          saveAs(returnedData, "BGT.zip");
        }
        if (this.selectedOption === "GML3") {
          saveAs(returnedData, "BGT.gml");
        }
        if (this.selectedOption === "application/json") {
          saveAs(returnedData, "BGT.json");
        }
        if (this.selectedOption === "application/vnd.google-earth.kml+xml") {
          saveAs(returnedData, "BGT.kml");
        }
        this.snackbar.dismiss();
      });


      



    
  }

}
