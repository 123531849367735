<div class="page">
  <div class="header">
    <div class="container">
      <div class="header__text">
        <h1>Gebruik de BGT WFS/WMS in uw applicatie</h1>
        <p>
          Wilt u de BGT integreren in uw eigen softwarepakket of applicatie? Met
          de WMS en WFS diensten van BGTviewer.nl is dat een fluitje van een
          cent.
        </p>
      </div>
      <img src="./assets/images/bgt-preview.jpg" class="header__image slide" width="801" height="503" />
    </div>
  </div>

  <div class="info">
    <div class="container">
      <div class="info__text">
        <h2>WMS en WFS</h2>
        <p>
          WMS en WFS zijn open standaarden ten behoeve van het opvragen,
          aanleveren en bewerken van geodata.
        </p>
      </div>
      <div class="infoboxes">
        <div class="infobox">
          <mat-icon>perm_media</mat-icon>
          <h3>WMS</h3>
          <p>
            WMS biedt een manier om een visueel overzicht te krijgen van
            complexe en gedistribueerde geografische kaarten.
          </p>
        </div>
        <div class="infobox">
          <mat-icon>cloud_download</mat-icon>
          <h3>WFS</h3>
          <p>
            Een Web Feature Service (WFS) is een interface voor het opvragen en
            aanleveren van geodata.
          </p>
        </div>
      </div>

      <a (click)="scroll(tarieven)" class="button button--center">
        Tarieven
      </a>
    </div>
  </div>
  <div class="related" #tarieven>
    <div class="container">
      <div class="pricing">
        <h2>Tarieven WFS & WMS</h2>
        <div class="pricing__container">
          <div class="pricing__table">
            <div class="pricing__header">
              <h2>Small use</h2>
            </div>
            <div class="pricing__price">
              €99 per maand
            </div>
            <div class="pricing__content">
              1-3 gebruikers
            </div>
            <div class="pricing__content">
              150.000 maptiles (WMS) / requests (WFS)
            </div>
          </div>
          <div class="pricing__table">
            <div class="pricing__header">
              <h2>Medium use</h2>
            </div>
            <div class="pricing__price">
              €199 per maand
            </div>
            <div class="pricing__content">
              3-10 gebruikers
            </div>
            <div class="pricing__content">
              500.000 maptiles (WMS) / requests (WFS)
            </div>
          </div>
          <div class="pricing__table">
            <div class="pricing__header">
              <h2>Large use</h2>
            </div>
            <div class="pricing__price">
              €299 per maand
            </div>
            <div class="pricing__content">
              10-20 gebruikers
            </div>
            <div class="pricing__content">
              1.000.000 maptiles (WMS) / requests (WFS)
            </div>
          </div>
        </div>

        <p>
          Meer dan 20 WMS- of WFS-gebruikers? Neem
          <a href="https://geogap.nl/geodata/bgt-wfs-wms/#contact">contact</a>
          met ons op voor voordelige maatwerktarieven.
        </p>
      </div>

      <app-link-about-bgt-viewer></app-link-about-bgt-viewer>
      <app-link-download-bgt></app-link-download-bgt>
      <app-link-about-bgt></app-link-about-bgt>
    </div>
  </div>
  <app-call-to-action></app-call-to-action>
</div>
