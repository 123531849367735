import { Injectable } from '@angular/core';

import layerNames from '../../assets/modes.json';
const castLayerNames = layerNames as any;

import bronHouders from '../../assets/bronhouders.json';
const castBronHouders = bronHouders as any;

@Injectable({
  providedIn:'root'
})
export class InfocardService {
  public isOpen = false;
  public data = null;
  public mode;
  public layerNames = castLayerNames;
  public bronHouders = castBronHouders;
  public displayName;
  public displayDescription;

  constructor() { }

  public open(data, mode) {
    this.mode = mode;
    this.displayName = this.mapModeToDisplayName(mode);
    this.displayDescription = this.mapModeToDescription(mode);
    if (data.properties.vbos) {
      data.properties.streets = this.parseVboData(data.properties.vbos);
    }
    this.data = data;
    this.isOpen = true;
  }

  private parseVboData(vboData){
    const adresData = JSON.parse(JSON.stringify(vboData));
    const nonUniqueStreets = adresData.map(adres => adres.properties.openbare_ruimte);
    const uniqueStreets = Array.from(new Set(nonUniqueStreets));
    return uniqueStreets;
  }

  public close() {
    this.isOpen = false;
  }

  private mapModeToDisplayName(mode) {
    return this.layerNames.find((layerName) => {
      return layerName.layer === mode;
    }).displayName;
  }

  private mapModeToDescription(mode) {
    return this.layerNames.find((layerName) => {
      return layerName.layer === mode;
    }).description;
  }

  public mapBronhoudercode(code) {
    return this.bronHouders.find((bronhouder) => {
      return bronhouder.BGTcode === code;
    }).Naam;
  }

}
