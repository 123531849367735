<div class="page">
  <div class="header">
    <div class="container">
      <div class="header__text">
        <h1>De Basisregistratie Grootschalige Topografie op de kaart</h1>
        <p>
          BGTviewer is het gratis toegangsportaal tot de Basisregistratie
          Grootschalige Topografie. De BGT wordt door gemeentes, ministeries en
          andere bronhouders samengesteld om gedetailleerde informatie te
          verstrekken over fysieke objecten in onze omgeving.
        </p>
      </div>
      <img src="./assets/images/bgt-preview.jpg" class="header__image slide" width="801" height="503" />
    </div>
  </div>

  <div class="info">
    <div class="container">
      <div class="info__text">
        <h2>Zo gebruikt u BGTviewer</h2>
        <p>
          BGTviewer is een nuttig hulpmiddel voor overheden, burgers en het
          bedrijfsleven.
        </p>
      </div>
      <div class="infoboxes">
        <div class="infobox">
          <mat-icon>search</mat-icon>
          <h3>Raadplegen</h3>
          <p>
            Raadpleeg de meest recente versie van de Basisregistratie
            Grootschalige Topografie.
          </p>
        </div>
        <div class="infobox">
          <mat-icon>work_outline</mat-icon>
          <h3>Bestuderen</h3>
          <p>
            Ieder BGT-object is aan te klikken voor aanvullende informatie over
            het object.
          </p>
        </div>
        <div class="infobox">
          <mat-icon>system_update_alt</mat-icon>
          <h3>Downloaden</h3>
          <p>
            Download de Basisregistratie Grootschalige Topografie voor het
            gebruik in uw software.
          </p>
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe gebruik ik de BGT als (landschaps)architect?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Met behulp van BGTviewer.nl kunnen delen uit de Basisregistratie
            Grootschalige Topografie eenvoudig worden gedownload als DXF, DWG, GML of
            Shapefile. Daardoor kunt u de BGT als ondergrondkaart gebruiken in
            uw projecten, bijvoorbeeld in Autocad. Kijk op deze pagina voor de
            verschillende bestandstypes en de ondersteunde software.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe gebruik ik de BGT als gemeenteambtenaar?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Met behulp van BGTviewer.nl kunt u op laagdrempelige wijze inzage
            krijgen in de Basisregistratie Grootschalige Topografie. Daardoor
            kunt u eenvoudig recente informatie opzoeken over de openbare
            ruimte, bijvoorbeeld ten behoeve van ondersteuning van burgers.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe gebruik ik de BGT als burger?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Met de BGTviewer.nl kunt u snel inzage krijgen in de
            Basisregistratie Grootschalige Topografie. Daardoor kunt u eenvoudig
            recente informatie opzoeken over de openbare ruimte, zoals die wordt
            beheerd door overheden.
          </p>
        </mat-expansion-panel>
      </mat-accordion>

      <a routerLink="/" class="button button--center">
        BGTviewer gebruiken
      </a>
    </div>
  </div>
  <div class="related">
    <div class="container">
      <app-link-about-bgt></app-link-about-bgt>
      <app-link-download-bgt></app-link-download-bgt>
      <app-link-bgt-wfs></app-link-bgt-wfs>
    </div>
  </div>
  <app-call-to-action></app-call-to-action>
</div>
