import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public baseUrl = environment.apiEndpointUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar) { }

  public activateAccount(code: string) {
    return this.http.get(this.baseUrl + 'auth/activate', {params: {code: code}}).toPromise();
  }

  public login(email: string, password: string) {
    return this.http.post(this.baseUrl + 'auth/login', { email, password }).toPromise()
      .catch(err => {
        console.error(err);
        throw new Error(err);
      });
  }

  public logout() {
    this.router.navigate(['./']);
    this.snackBar.open('U bent uitgelogd.', null, { duration: 3500 });
  }

  public get accessToken() {
    const token = JSON.parse(localStorage.getItem('currentUser3'))?.token;
    return token;
  }

  public checkEmailAvailability(email: string) {
    return this.http.post(this.baseUrl + 'auth/checkemail', { email: email }).toPromise();
  }

  public createAccount(options: {email: string, password: string, telephone: string}) {
    return this.http.post(this.baseUrl + 'auth/createuser', options).toPromise();
  }

  public makeEmailResetRequest(email: string) {
    return this.http.post(this.baseUrl + 'auth/resetpassword', { email: email }).toPromise().then(response => {
      return response;
    })
  }

  public getUserByResetCode(resetcode: string) {
    return this.http.post(this.baseUrl + 'auth/getuserbyresetcode', { resetCode: resetcode }).toPromise().then(response => {
      return response;
    })
  }

  public checkIfCodeExists(code: string) {
    return this.http.get(this.baseUrl + 'auth/check-code?', {params: {code: code}}).toPromise();
  }

  public changePassword(email: string, newPassword: string, resetCode: string) {
    return this.http.post(this.baseUrl + 'auth/changepassword', { email: email, newPassword: newPassword, resetCode: resetCode }).toPromise().then(response => {
      return response;
    });
  }
}
