import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface CurrentUser {
    token: string;
    email: string;
    credits: number;
    admin: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UserStore {
    // - We set the initial state in BehaviorSubject's constructor
    // - Nobody outside the Store should have access to the BehaviorSubject
    //   because it has the write rights
    // - Writing to state should be handled by specialized Store methods
    //   create a new BehaviorSubject for it, as well as the observable$, and getters/setters
    private readonly _currentUser = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentUser3') || null));
    public currentUser$ = this._currentUser;

    constructor() {
        this.currentUser$.subscribe(currentUser => {
            localStorage.setItem('currentUser3', JSON.stringify(currentUser));
        });
    }

    private get currentUser(): CurrentUser {
        return this._currentUser.getValue();
    }

    private set currentUser(val: CurrentUser) {
        this._currentUser.next(val);
    }

    public hasEnoughCredits() {
        return this.currentUser.credits > 0;
    }

    public isLoggedIn() {
        return this.currentUser !== null;
    }

    public getUserAccessToken() {
        return this.currentUser.token;
    }

    public setCredits(creditAmount: number) {
        const user = this.currentUser;
        user.credits = creditAmount;
        this.currentUser = user;
    }

    public useCredit() {
        const user = this.currentUser;
        user.credits -= 1;
        this.currentUser = user;
    }

    public setCurrentUser(currentUser: CurrentUser) {
        this.currentUser = currentUser;
    }

    public clearCurrentUser() {
        this.currentUser = null;
    }
}
