import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import * as L from "leaflet";
import { Config } from "../app.config";

import * as proj3 from "proj4";
import { InfocardService } from "./infocard.service";
const proj4 = (proj3 as any).default;

@Injectable({
  providedIn: 'root'
})
export class MapInteractionService {
  public xyz = new Subject();
  public activeBaselayer = new Subject();
  private activeOverlaysSubject = new Subject();
  private activeOverlays = [];
  private highlightFeatureSubject = new Subject();
  public mapForStatistics: L.Map;
  public zoomLevel = new Subject();

  public zoomStatus;

  constructor(private infocardService: InfocardService) {}

  public setBaselayer(baseLayer: L.Layer) {
    this.activeBaselayer.next(baseLayer);
  }

  public getActiveOverlays() {
    return this.activeOverlaysSubject.asObservable();
  }

  public addActiveOverlay(overlay: any) {
    this.activeOverlays.push(overlay);
    this.activeOverlaysSubject.next(this.activeOverlays);
  }

  public removeActiveOverlay(overlay: any) {
    this.activeOverlays = this.activeOverlays.filter(
      o => o.layerConfig.name !== overlay.layerConfig.name
    );
    this.activeOverlaysSubject.next(this.activeOverlays);
  }

  public inverseBboxString(latLng: any) {
    // Sometimes bounds are needed inverted because WFS uses 4326 notation, to minlat, minlng, etc...
    const swLatLng = [latLng._southWest.lng, latLng._southWest.lat];
    const neLatLng = [latLng._northEast.lng, latLng._northEast.lat];
    const swRD = proj4(
      Config.projections.EPSG4326,
      Config.projections.EPSG28992
    ).forward(swLatLng);
    const neRD = proj4(
      Config.projections.EPSG4326,
      Config.projections.EPSG28992
    ).forward(neLatLng);
    const bboxString = swRD + "," + neRD;
    return bboxString;
  }

  public setHighlightFeatureAndOpenInfocard(feature) {
    const mode = feature.id.split(".")[0];
    this.highlightFeatureSubject.next(feature);
    this.infocardService.open(feature, mode);
  }

  public closeInfocard() {
    this.infocardService.close();
  }

  public clearHighlightFeature() {
    this.highlightFeatureSubject.next(null);
  }

  public getHighlightFeature() {
    return this.highlightFeatureSubject.asObservable();
  }

  public setMap(map) {
    this.mapForStatistics = map;
  }

  public setXYZ(x, y, z) {
    this.xyz.next({ x, y, z });
  }

  public getXYZ() {
    return this.xyz.asObservable();
  }

  setZoomStatus(showTheWarning) {
    this.zoomStatus = showTheWarning;
  }

  getZoomStatus() {
    return this.zoomStatus;
  }

  setZoomLevel(zoomLevel) {
    this.zoomLevel.next(zoomLevel);
  }

  getZoomLevel() {
    return this.zoomLevel.asObservable();
  }
}
