<h3> Download-credits vereist</h3>

<div class="login-text">Met een download-credit voor BGT viewer kunt u het kaartbeeld downloaden als DWG, DXF, ESRI Shapefile, GML, KML of GeoJSON.</div>

<div class="login-text">Er kunnen losse downloads worden aangeschaft, of er kan een lidmaatschap worden afgesloten met
    forse kortingen per download.</div>

<div class="login-text">
    Zolang u over een download-credit beschikt, kunt u hiernaast onbeperkt gebruik maken van de handige meetfunctionaliteit.
</div>

<div class="login-text">Klik op de knop hieronder om de tarieven te bekijken.</div>

<a routerLink="/app/tarieven">
    <button class="login-button" (click)="dialogRef.close()" mat-button> Bekijk de tarieven </button>
</a>