import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.enableGoogleAnalytics) {
  const node = document.createElement('script');
  node.src = `https://www.googletagmanager.com/gtag/js?id=G-MGB9XY43CD`;
  node.async = true;
  document.getElementsByTagName('head')[0].appendChild(node);
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
