import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { UserStore } from './stores/user.store';
@Injectable({
  providedIn: 'root'
})
export class AppHttpService {

  constructor(private http: HttpClient, private auth: AuthService, private userStore: UserStore) { }

    public currentRequests = 0;

    public get isRequestsRunning() {
      return this.currentRequests > 0;
    }

    public get(url: string, options?: any): Promise<any> {
      this.addRequest();
      return this.http.get(url, options).toPromise()
        .then(result => {
          this.removeRequest();
          return result;
        })
        .catch(error => {
          this.removeRequest();
          return error;
        });
    }

    public post(url: string, body: any, options?: any): Promise<any> {
      this.addRequest();
      return this.http.post(url, body, options).toPromise()
        .then(result => {
          this.removeRequest();
          return result;
        })
        .catch(error => {
          this.removeRequest();
          return error;
        });
    }

    public put(url: string, body: any, options?: any): Promise<any> {
      this.addRequest();
      return this.http.put(url, body, options).toPromise()
        .then(result => {
          this.removeRequest();
          return result;
        })
        .catch(error => {
          this.removeRequest();
          return error;
        });
    }

    public delete(url: string, options?: any): Promise<any> {
      this.addRequest();
      return this.http.delete(url, options).toPromise()
        .then(result => {
          this.removeRequest();
          return result;
        })
        .catch(error => {
          this.removeRequest();
          return error;
        });
    }

    public createAuthHeader() {
      if (this.userStore.isLoggedIn() === true) {
        return new HttpHeaders().set('Authorization', 'JWT ' + this.auth.accessToken);
      } else {
        return new HttpHeaders();
      }
     
    }

    private addRequest(): void {
      this.currentRequests += 1;
    }

    private removeRequest(): void {
      this.currentRequests -= 1;
    }
}
