import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'please-register-dialog-component',
  templateUrl: 'please-register-dialog.html',
  styleUrls: ['./please-register-dialog.scss']
})
export class PleaseRegisterDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PleaseRegisterDialog>) {
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeInfocard() {
    this.dialogRef.close();
  }

}
