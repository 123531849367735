<div class="page">
  <div class="header">
    <div class="container">
      <div class="header__text">
        <h1>Veelgestelde vragen over BGTviewer</h1>
        <p>
          Hieronder zetten we de meest veelgestelde vragen over BGTviewer op een
          rijtje.
        </p>
      </div>
      <img src="./assets/images/bgt-preview.jpg" class="header__image slide" width="801" height="503" />
    </div>
  </div>

  <div class="info">
    <div class="container">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Wat zijn de bestandsformaten waarin de BGT gedownload kan worden?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De BGT kan worden gedownload als DXF, DWG, ESRI Shapefile, GML, KML en
            GeoJSON. Daardoor kunnen de downloads onder meer gebruikt worden in
            AutoCAD, Revit, BentleyMap, ArcGIS, Illustrator, QGIS en FME.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Wat zijn de tarieven van de lidmaatschappen?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De tarieven van de BGT-viewer lidmaatschappen zijn te vinden via
            <a routerLink="/info/bgt-gegevens-lidmaatschappen">deze pagina</a>.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe worden de lidmaatschappen betaald?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De lidmaatschappen worden afgerekend via een maandelijkse automatische
            incasso, waarna er een BTW-factuur wordt verzonden naar de afnemer.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Wat is de looptijd en opzegtermijn van de lidmaatschappen?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            lidmaatschappen op BGTviewer.nl worden afgesloten voor een onbeperkte
            duur en zijn maandelijks opzegbaar.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Wat zijn de verdere voorwaarden van de BGTviewer-lidmaatschappen?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De volledige voorwaarden zijn te vinden via
            <a
              href="https://geogap.nl/productvoorwaardenBGT.pdf"
              rel="noopener"
              target="_blank"
              >deze link</a
            >.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Welke data bevindt zich in de BGT?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De BGT is een gedetailleerde digitale kaart van Nederland, waarin
            gebouwen, wegen, water, spoorlijnen en groen voor heel Nederland op
            dezelfde manier zijn vastgelegd. Overheden gebruiken de BGT als
            basis voor het beheren van de openbare ruimte, maar ook als een
            eenduidige kaartondergrond. De BGT wordt vaak (gecombineerd)
            gebruikt met andere basisregistraties en locatiegegevens. Daarnaast
            geeft de BGT een betrouwbaar en accuraat kaartbeeld dat als
            ondergrond kan dienen voor een grote hoeveelheid aan activiteiten.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Wie is er verantwoordelijk voor de data in de BGT?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De BGT wordt gemaakt en bijgehouden door zogeheten bronhouders. Dit
            zijn er zeven: gemeenten, provincies, waterschappen, het Ministerie
            van Economische Zaken (Landbouw), Defensie, Rijkswaterstaat en
            ProRail. In het verleden maakte iedereen een eigen basiskaart. Met
            de komst van de BGT gaan deze verschillende kaarten op in één
            uniforme digitale basiskaart. Hierdoor worden de gegevens beter
            uitwisselbaar, efficiënter ingewonnen en toegankelijker.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe vaak wordt de BGT ververst?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            De bronhouders zijn verantwoordelijk voor het actualiseren van de
            BGT objecten. Zogenaamde ‘mutaties’ worden dagelijks verwerkt in de
            landelijke voorziening. Deze mutaties worden maandelijks via
            BGTviewer.nl beschikbaar gemaakt.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe gebruik ik de BGT als (landschaps)architect?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Met behulp van BGTviewer.nl kunnen delen uit de Basisregistratie
            Grootschalige Topografie eenvoudig worden gedownload als DXF, DWG, GML of
            Shapefile. Daardoor kunt u de BGT als ondergrondkaart gebruiken in
            uw projecten, bijvoorbeeld in Autocad. Kijk op deze pagina voor de
            verschillende bestandstypes en de ondersteunde software.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe gebruik ik de BGT als gemeenteambtenaar?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Met behulp van BGTviewer.nl kunt u op laagdrempelige wijze inzage
            krijgen in de Basisregistratie Grootschalige Topografie. Daardoor
            kunt u eenvoudig recente informatie opzoeken over de openbare
            ruimte, bijvoorbeeld ten behoeve van ondersteuning van burgers.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            collapsedHeight="70px"
            expandedHeight="70px"
          >
            <mat-panel-title>
              Hoe gebruik ik de BGT als burger?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Met de BGTviewer.nl kunt u snel inzage krijgen in de
            Basisregistratie Grootschalige Topografie. Daardoor kunt u eenvoudig
            recente informatie opzoeken over de openbare ruimte, zoals die wordt
            beheerd door overheden.
          </p>
        </mat-expansion-panel>
      </mat-accordion>

      <a routerLink="/" class="button button--center">
        BGTviewer gebruiken
      </a>
    </div>
  </div>
  <div class="related">
    <div class="container">
      <app-link-about-bgt></app-link-about-bgt>
      <app-link-download-bgt></app-link-download-bgt>
      <app-link-bgt-wfs></app-link-bgt-wfs>
    </div>
  </div>
  <app-call-to-action></app-call-to-action>
</div>
