import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import * as L from 'leaflet';
import { Config } from '../app.config';
import * as proj3 from 'proj4';
import { environment } from '../../environments/environment';
const geoserverUrl = `${environment.geoserver.protocol}://${environment.geoserver.host}:${environment.geoserver.port}/${environment.geoserver.version}`;

const proj4 = (proj3 as any).default;

@Injectable({
  providedIn: 'root'
})
export class BgtService {
  private apiUrl = environment.apiEndpointUrl;
  private bgtUrl = `${geoserverUrl}/ows?`;
  private landsdekkendeFeatures = [
    'bgt_v2:onbegroeidterreindeel',
    'bgt_v2:begroeidterreindeel',
    'bgt_v2:pand',
    'bgt_v2:wegdeel',
    'bgt_v2:ondersteunendwegdeel',
    'bgt_v2:ondersteunendwaterdeel',
    'bgt_v2:waterdeel',
    'bgt_v2:overigbouwwerk',
    'bgt_v2:scheiding_vlak'];
  constructor(public http: AppHttpService) {
  }

  public getFeature(typename: string, bboxString: string) {
    const params = {
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      srsName: 'EPSG:4326',
      typeName: typename,
      outputFormat: 'application/json',
      bbox: bboxString,
      count: 1500
    };

    return this.http.get(this.bgtUrl, {
      params: params,
      headers: this.http.createAuthHeader(),
    })
      .then(response => {
        return response;
      });
  }

  public getCoverageFeature(latLng: L.LatLng) {
    const xy = this.latLngToXy(latLng);
    const wktPoint = 'POINT(' + xy[0] + ' ' + xy[1] + ')';
    const cqlFilter = 'CONTAINS(geometrie_vlak,' + wktPoint + ')';

    const params = {
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      typeName: this.landsdekkendeFeatures.toString(),
      outputFormat: 'application/json',
      cql_filter: cqlFilter,
      srsName: 'EPSG:4326'
    };
    return this.http.get(this.bgtUrl, { 
      params: params,
      headers: this.http.createAuthHeader(),
     }).then(featureCollection => {
      let feature = {};
      if (featureCollection.features.length > 0) {
        feature = featureCollection.features[0];
      }
      return feature;
    });
  }

  public downloadFeatures(bbox: any, format) {
    // Reproject features first
    const southWest = this.latLngToXy(bbox._southWest);
    const northEast = this.latLngToXy(bbox._northEast);
    let autocadFix = false;
    if (format === 'dxf') {
      autocadFix = true;
    }
    return this.http.post(this.apiUrl + `api/download/bgt/${southWest[0]}/${southWest[1]}/${northEast[0]}/${northEast[1]}`, {
      format: format,
      autocadFix: autocadFix
    }, {
      headers: this.http.createAuthHeader(),
      responseType: 'blob',
    });
  }

  private latLngToXy(latlng) {
    latlng = [latlng.lng, latlng.lat];
    const xy = proj4(Config.projections.EPSG4326, Config.projections.EPSG28992).forward(latlng);
    return xy;
  }

}
