<div class="inforow inforow--leftimage">
  <div class="inforow__left">
    <img src="./assets/images/bgt-viewer-download.png" />
  </div>
  <div class="inforow__right">
    <h3>BGT-gegevens downloaden</h3>
    <p>
      BGTviewer maakt het mogelijk om gegevens uit de Basisregistratie
      Grootschalige Topografie te downloaden. Zo kan deze actuele, nauwkeurige
      kaart worden gebruikt in programma’s als AutoCAD, Revit en Qgis,
      bijvoorbeeld als ondergrond om projecten in te tekenen.
    </p>
    <a routerLink="/info/bgt-gegevens-downloaden" class="button">
      Over de downloadservice
    </a>
  </div>
</div>
