import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
  ) {}

  public addSeoData(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let root = this.router.routerState.snapshot.root;
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0];
        } else if (root.data && root.data['getTitle']) {
          // dynamic
          this.setDynamicTags(root);
          return;
        } else if (root.data && root.data['title']) {
          // static
          this.setStaticTags(root);
          return;
        } else {
          return;
        }
      }
    });
  }


  public setDynamicTags(root: ActivatedRouteSnapshot) {
    this.setTitle(root.data.getTitle(root.data, root.params));
    const tags = root.data['getMetatags'];
    if (tags) {
      Object.entries(tags).forEach(([tag, getValue]: [string, Function]) => {
        this.updateTag(tag, getValue(root.data, root.params));
      });
    }
  }

  public setStaticTags(root: ActivatedRouteSnapshot) {
    this.setTitle(root.data['title']);
    const tags = root.data['metatags'];
    if (tags) {
      Object.entries(tags).forEach(([tag, value]: [string, string]) => {
        this.updateTag(tag, value);
      });
    }
  }

  public updateTag(tagName: string, content: string) {
    this.metaService.removeTag(`name='${tagName}'`);
    this.metaService.addTag({ name: tagName, content: content });
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
