import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'download-information-dialog-component',
  templateUrl: 'download-information-dialog.html',
  styleUrls: ['./extra-information-dialog.scss']
})
export class DownloadInformationDialog {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialogRef: MatDialogRef<DownloadInformationDialog>) {
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeInfocard() {
    this.dialogRef.close();
  }

}
