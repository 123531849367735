<div class="subpage">
  <div class="subpage__content">
    <div class="subpage__header">
      <mat-icon>info_outline</mat-icon>
      <h3 class="subpage__title">Over de BGTviewer</h3>
    </div>
    <p class="subpage__text">
      BGTviewer.nl is een interactieve online kaart die inzicht geeft in de
      Basisregistratie Grootschalige Topografie. De BGT wordt door gemeentes,
      ministeries en andere bronhouders samengesteld om gedetailleerde
      informatie te verstrekken over fysieke objecten in onze omgeving.
    </p>

    <p class="subpage__text">
      BGTviewer.nl biedt u de mogelijkheid om het huidige kaartbeeld te downloaden. De tarieven voor het gebruiken
      van deze dienst zijn <a routerLink="/app/tarieven">hier beschikbaar</a>. Ook is het mogelijk om een WFS en WMS van
      de BGT af te nemen. Neem voor meer informatie over de WFS en WMS contact met ons op via
      <a href="mailto:info@geogap@@nl" onmouseover="this.href=this.href.replace('@@','.')">info@geogap.nl</a>.
    </p>

    <p class="subpage__text">BGTviewer.nl wordt mogelijk gemaakt door:</p>

    <a href="https://geogap.nl/" class="link" target="_blank"> Geogap </a>

    <a href="http://www.nlextract.nl/" class="link" target="_blank">
      NLExtract
    </a>

    <a href="http://google.com" class="link" target="_blank"> Google </a>

    <p class="subpage__text">
      De BGT-data die in de BGTviewer wordt weergegeven is door PDOK beschikbaar
      gesteld via de
      <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC-BY-4.0 licentie</a>.
    </p>
  </div>
</div>