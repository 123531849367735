<div class="page">
  <div class="header">
    <div class="container">
      <div class="header__text">
        <h1>BGT-gegevens downloaden</h1>
        <p>
          BGTviewer.nl biedt de mogelijkheid om onderdelen uit de
          Basisregistratie Grootschalige Topografie te downloaden. Daardoor kan
          de BGT als ondergrondkaart worden gebruikt in programma’s als Autocad,
          zodat het als startpunt kan dienen voor uw ruimtelijke tekeningen.
        </p>
      </div>
      <img src="./assets/images/bgt-preview.jpg" class="header__image slide" width="801" height="503"/>
    </div>
  </div>

  <div class="info">
    <div class="container">
      <div class="info__text">
        <h2>Gebruiksmogelijkheden</h2>
        <p>
          BGTviewer.nl biedt de mogelijkheid om de Basisregistratie
          Grootschalige Topografie als ondergrondkaart te gebruiken. Daartoe kan
          de BGT worden gedownload als DXF, DWG, ESRI Shapefile, GML, KML en GeoJSON.
          De downloads kunnen onder meer gebruikt worden in AutoCAD, Revit,
          BentleyMap, ArcGIS, Illustrator, QGIS en FME. Er zijn verschillende
          lidmaatschappen beschikbaar:
        </p>
      </div>
      <div class="infoboxes">
        <div class="infobox">
          <img src="./assets/images/bgt-brons.png" />
          <h3>Brons</h3>
          <p>
            10 downloads voor 25 euro per maand
          </p>
        </div>
        <div class="infobox">
          <img src="./assets/images/bgt-zilver.png" />
          <h3>Zilver</h3>
          <p>
            50 downloads voor 50 euro per maand
          </p>
        </div>
        <div class="infobox">
          <img src="./assets/images/bgt-goud.png" />
          <h3>Goud</h3>
          <p>
            150 downloads voor 100 euro per maand
          </p>
        </div>
      </div>

      <a routerLink="/app/tarieven" class="button button--center">
        BGTviewer gebruiken
      </a>
    </div>
  </div>
  <div class="related">
    <div class="container">
      <app-link-about-bgt></app-link-about-bgt>
      <div class="inverse">
        <app-link-about-bgt-viewer></app-link-about-bgt-viewer>
      </div>
      <app-link-bgt-wfs></app-link-bgt-wfs>
    </div>
  </div>
  <app-call-to-action></app-call-to-action>
</div>
