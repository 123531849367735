import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MatIconModule } from '@angular/material/icon';
import { ColofonPageComponent } from './colofon/colofon.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { AboutBgtComponent } from './about-bgt/about-bgt.component';
import { DownloadComponent } from './download/download.component';
import { WmsWfsComponent } from './wms-wfs/wms-wfs.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CallToActionComponent } from './shared/call-to-action/call-to-action.component';
import { LinkAboutBgtComponent } from './shared/link-about-bgt/link-about-bgt.component';
import { LinkDownloadBgtComponent } from './shared/link-download-bgt/link-download-bgt.component';
import { LinkBgtWfsComponent } from './shared/link-bgt-wfs/link-bgt-wfs.component';
import { LinkAboutBgtViewerComponent } from './shared/link-about-bgt-viewer/link-about-bgt-viewer.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    ColofonPageComponent,
    AboutComponent,
    FaqComponent,
    AboutBgtComponent,
    DownloadComponent,
    WmsWfsComponent,
    CallToActionComponent,
    LinkAboutBgtComponent,
    LinkDownloadBgtComponent,
    LinkBgtWfsComponent,
    LinkAboutBgtViewerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    RouterModule
  ],
  exports: [
    PageNotFoundComponent,
    ColofonPageComponent
  ]
})
export class PagesModule { }
