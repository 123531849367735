<h3>
  <mat-icon (click)="closeInfocard()">close</mat-icon>
  Download het huidige kaartbeeld
</h3>
<p>
  U bent ingelogd als <strong>{{userName$ | async}}</strong>. Dit
  account beschikt op dit moment over
  <span class="credit-count">{{userCredits$ | async}}</span> credits. Elke download
  kost één credit.
</p>
<p>Kies een bestandsformaat:</p>
<mat-form-field class="download-select">
  <mat-select [(value)]="selectedOption">
    <mat-option value="dwg">DWG</mat-option>
    <mat-option value="dxf">DXF</mat-option>
    <mat-option value="shape-zip">Shapefile</mat-option>
    <mat-option value="GML3">GML</mat-option>
    <mat-option value="application/json">GeoJSON</mat-option>
    <mat-option value="application/vnd.google-earth.kml+xml">KML</mat-option>
  </mat-select>
</mat-form-field>

<button class="download-button" mat-raised-button (click)="download()">
  Download het kaartbeeld
</button>

<p>
  De tarieven van de shapefile download service zijn
  <a routerLink="/app/tarieven">hier beschikbaar</a>. Neem contact op via
  <a
    href="mailto:info@geogap@@nl"
    onmouseover="this.href=this.href.replace('@@','.')"
    >info@geogap.nl</a
  >
  om aanvullende credits te bestellen.
</p>
