<div class="inforow">
  <div class="inforow__left">
    <h3>WMS en WFS van de BGT</h3>
    <p>
      Via BGTviewer is het tevens mogelijk om een WFS- en WMS-dienst van de
      Basisregistratie Grootschalige Topografie af te nemen. Daarmee wordt het
      mogelijk om de BGT als ondergrondkaart in uw eigen applicaties te
      gebruiken.
    </p>
    <a routerLink="/info/bgt-wms-wfs" class="button">
      BGT WMS en WFS
    </a>
  </div>
  <div class="inforow__right">
    <img src="./assets/images/bgt-route.png" />
  </div>
</div>
