import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-wms-wfs",
  templateUrl: "./wms-wfs.component.html",
  styleUrls: ["./wms-wfs.component.scss"],
})
export class WmsWfsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }
}
