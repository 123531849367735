import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-about-bgt-viewer',
  templateUrl: './link-about-bgt-viewer.component.html',
  styleUrls: ['./link-about-bgt-viewer.component.scss']
})
export class LinkAboutBgtViewerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
