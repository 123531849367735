import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { ColofonPageComponent } from "./pages/colofon/colofon.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { FaqComponent } from './pages/faq/faq.component';
import { AboutComponent } from './pages/about/about.component';
import { AboutBgtComponent } from './pages/about-bgt/about-bgt.component';
import { DownloadComponent } from './pages/download/download.component';
import { WmsWfsComponent } from './pages/wms-wfs/wms-wfs.component';
import { AdminComponent } from "./feature/admin/admin.component";

const routes: Routes = [
  {
    path: 'registratie',
    loadChildren: () => import('./feature/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./feature/user/user.module').then(m => m.UserModule)
  },
  {
    path: "colofon",
    component: ColofonPageComponent,
    data: {
      title: 'Colofon - BGTviewer',
      metatags: {
        description: 'U vind hier informatie over de website BGTviewer.nl, waarmee u via een kaart inzicht krijgt in de Basisregistratie Grootschalige Topografie en inhoud uit de BGT kunt downloaden.'
      }
    }
  },
  {
    path: "pagina-niet-gevonden",
    component: PageNotFoundComponent,
    data: {
      title: 'Pagina niet gevonden - BGTviewer',
      metatags: {
        description: 'We hebben uw opgevraagde pagina niet kunnen vinden.'
      }
    }
  },
  {
    path: "info",
    children: [
      {
        path: 'faq',
        component: FaqComponent,
        data: {
          title: 'Veelgestelde vragen - BGTviewer',
          metatags: {
            description: 'Hier vindt u de meest veelgestelde vragen over de BGTviewer, waaronder over bestandsformaten, lidmaatschappen en de inhoud van de BGT.'
          }
        }
      },
      {
        path: 'over',
        component: AboutComponent,
        data: {
          title: 'Over BGTviewer - BGTviewer.nl',
          metatags: {
            description: 'U vind hier informatie over de website BGTviewer.nl, waarmee u via een kaart inzicht krijgt in de Basisregistratie Grootschalige Topografie en inhoud uit de BGT kunt downloaden.'
          }
        }
      },
      {
        path: 'over-de-bgt',
        component: AboutBgtComponent,
        data: {
          title: 'Over de Basisregistratie Grootschalige Topografie - BGTviewer',
        metatags: {
            description: 'De BGT is dé basiskaart van Nederland: gemeentes, provincies en waterschappen houden op accurate wijze bij wat er zich in de openbare ruimte bevindt...'
          }
        }
      },
      {
        path: 'bgt-gegevens-downloaden',
        component: DownloadComponent,
        data: {
          title: 'BGT-gegevens downloaden - BGTviewer',
          metatags: {
            description: 'BGTviewer.nl biedt de mogelijkheid om onderdelen uit de Basisregistratie Grootschalige Topografie te downloaden. Daardoor kan de BGT als ondergrondkaart dienen'
          }
        }
      }, 
      {
        path: 'bgt-wms-wfs',
        component: WmsWfsComponent,
        data: {
          title: 'WMS & WFS van BGT - BGTviewer',
          metatags: {
            description: 'Via BGTviewer is het mogelijk om de BGT in uw applicatie te gebruiken. Daartoe bieden wij zowel een WMS als een WFS van de BGT aan.'
          }
        }
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./feature/map/map.module').then(m => m.MapModule),
    data: {
      title: 'BGTviewer.nl - de Basisregistratie Grootschalige Topografie' ,
      metatags: {
        description: 'BGTviewer.nl is hét startpunt voor de Basisregistratie Grootschalige Topografie, de gestandaardiseerde manier om de topografie van Nederland vast te leggen en openbaar te maken.'
      }
    }
  },
  {

    path: 'admin',
    component: AdminComponent
  },
  {
    path: "**",
    redirectTo: "pagina-niet-gevonden"
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRouterModule { }
