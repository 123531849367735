import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';

@Injectable({
  providedIn: 'root'
})
export class SpinService {

  constructor(private api: AppHttpService) { }

  public get showSpinner (): boolean {
    let showIt = false;
    if (this.api.isRequestsRunning) {
      showIt = true;
    }
    return showIt;
  }

}
