import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private router: Router) {
    if (!this.shouldSend()) {
      return;
    }
  }

  
  public track() {
    if (!this.shouldSend()) {
      return;
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.emitPageview(event.urlAfterRedirects);
    });
  }

  public event(eventName: string, opts?: { [key: string]: any }) {
    if (!this.shouldSend()) {
      return;
    }
    gtag("event", eventName, {
      ...opts,
    });
  }


  public emitPageview(url: string) {
    if (!this.shouldSend()) {
      return;
    }

    this.event('page_view', {
      page_title: document.title,
      page_location: location.href
    })
  }


  private shouldSend(): boolean {
    let answer = false;
    if (environment.enableGoogleAnalytics) {
      answer = true;
    }
    return answer;
  }

}