<div class="inforow">
  <div class="inforow__left">
    <h3>Over BGTviewer</h3>
    <p>
      BGTviewer.nl is een interactieve kaart die de Basisregistratie
      Grootschalige Topografie inzichtelijk maakt. De website is gratis voor
      eenieder toegankelijk en is zowel op desktops, laptops en mobiele
      apparaten te gebruiken.
    </p>
    <a routerLink="/info/over" class="button">
      Over BGTviewer
    </a>
  </div>
  <div class="inforow__right">
    <img src="./assets/images/bgt-search.png" />
  </div>
</div>
