import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    public baseUrl = environment.apiEndpointUrl;

    constructor(private http: AppHttpService, private httpClient: HttpClient) {
    }

    public listUsers(q: string) {
        return this.httpClient.get(this.baseUrl + 'admin/users', { headers: this.http.createAuthHeader(), params: { q } })
    }

    public cancelSubscription(userId: number) {
        return this.httpClient.delete(this.baseUrl + `admin/users/${userId}/subscriptions`, { headers: this.http.createAuthHeader() })
    }

    public addUserCredits(credits: number, userId: number) {
        return this.httpClient.post(this.baseUrl + `admin/users/${userId}/credits`, { credits: credits }, { headers: this.http.createAuthHeader() });
    }

}
