<div class="inforow">
  <div class="inforow__left">
    <h3>Wat is de BGT?</h3>
    <p>
      De Basisregistratie Grootschalige Topografie is een gedetailleerde kaart
      van Nederland waarin objecten zoals gebouwen, wegen, water, spoorlijnen en
      groen op eenduidige manier worden vastgelegd. De kaart is op 20 centimeter
      nauwkeurig, altijd zeer actueel en landsdekkend. Dat maakt de BGT erg
      geschikt voor iedereen die met ruimtelijke gegevens werkt.
    </p>
    <a routerLink="/info/over-de-bgt" class="button">
      Over de BGT
    </a>
  </div>
  <div class="inforow__right">
    <img src="./assets/images/bgt-info.png" />
  </div>
</div>
