<div class="header" color="bgt-primary">
  <h1 routerLink="/" class="header__title"><span>bgt</span>viewer</h1>

  <app-spin></app-spin>

  <div class="header__buttons">
    <button *ngIf="!kaartViewActive" routerLink="/" mat-button>
      <mat-icon>public</mat-icon>
      Kaart
    </button>

    <button class="download" *ngIf="kaartViewActive" (click)="openDownloadDialog()" mat-button>
      <mat-icon>file_download</mat-icon>
      Downloaden
    </button>
    <button class="login" *ngIf="(authenticated$ | async) === false" routerLink="/registratie/login" mat-button>
      <mat-icon>person</mat-icon>
      Inloggen
    </button>
    <button class="login" *ngIf="(authenticated$ | async) === false" routerLink="/registratie/registreren" mat-button>
      <mat-icon>person_add</mat-icon>
      Account aanmaken
    </button>
    <button class="login" *ngIf="(authenticated$ | async) === true" routerLink="/app/account" mat-button
      matTooltip="Accountgegevens">
      <mat-icon>person_outline</mat-icon>
      {{ userName$ | async }} ({{ credits$ | async }})
    </button>

    <div class="header__menu">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
        Menu
      </button>
      <mat-menu #menu="matMenu">
        <button class="login" *ngIf="(authenticated$ | async) === false" routerLink="/registratie/login" mat-menu-item>
          <span>Inloggen</span>
        </button>
        <button class="login" *ngIf="(authenticated$ | async) === true" routerLink="/app/account" mat-menu-item>
          <span> {{ userName$ | async }} ({{ credits$ | async }}) </span>
        </button>
        <button routerLink="/registratie/registreren" *ngIf="(authenticated$ | async) === false" mat-menu-item>
          <span>Registeren</span>
        </button>
        <a routerLink="./info/over"><button mat-menu-item>
            <span>Over BGTviewer</span>
          </button></a>
        <button class="download" *ngIf="kaartViewActive" (click)="openDownloadDialog()" mat-menu-item>
          <span>Downloaden</span>
        </button>

        <button routerLink="/info/over-de-bgt" mat-menu-item>
          <span>Wat is de BGT?</span>
        </button>


        <button routerLink="/info/bgt-wms-wfs" mat-menu-item>
          <span>BGT WMS/WFS</span>
        </button>

        <button routerLink="/app/tarieven" mat-menu-item>
          <span>Tarieven</span>
        </button>
        <a routerLink="./info/faq/"><button mat-menu-item>
            <span>Veelgestelde vragen</span>
          </button></a>
        <button routerLink="/colofon" mat-menu-item>
          <span>Colofon</span>
        </button>
        <button onClick="window.open('https://geogap.nl/PrivacyPolicyGeogap.pdf');" mat-menu-item>
          <span>Privacybeleid</span>
        </button>

        <button *ngIf="(user$ | async)?.admin === true" routerLink="/admin" mat-menu-item>
          <span>Admin</span>
        </button>
        <button *ngIf="(authenticated$ | async) === true" (click)="openLogoutDialog()" mat-menu-item>
          <span>Uitloggen</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>